html {
	background-color: #000000;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}

@media only screen and (max-width: 1170px) {
}

@media (min-width: 576px) {
}

.spacer-container{
    height: 30vh;
}